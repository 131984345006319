import React, { useState } from "react"
import { css } from "@emotion/react"

import BookLayout from "../../components/BookLayout"

const Success = () => {
  return (
    <BookLayout>
      <div>
        <h1
          css={css`
            text-align: center;
            margin-top: 20px !important;
          `}
        >
          Order Successful
        </h1>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: center;
          margin-top: 10px;
          text-align: center;
        `}
      >
        <p
          css={css`
            padding: 0 20px;
          `}
        >
          Thank you for your purchase :) <br /> <br /> The first chapters will
          be sent starting on Sunday, May 16th, 2021. <br /> <br /> If you have
          any issues, email me at dkb@dkb.fyi.
        </p>
      </div>
    </BookLayout>
  )
}

export default Success
